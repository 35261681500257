// @ts-nocheck
//import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

import { DataAwareProps, withData } from 'components/DataContext';
import { observer } from 'mobx-react';

import Grid from '@material-ui/core/Grid';
import HyperLink from 'components/Links/HyperLink';
import { Link as RouterLink } from 'react-router-dom';

import Collapse from '@material-ui/core/Collapse';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import KAUN from 'components/KAUN';
import SocialBar from 'components/SocialBar';

import KAUNToggle from 'components/NavBar/KAUNToggle';
import LegalImage from 'images/NavDrawer/legal.svg';
import PressImage from 'images/NavDrawer/press.svg';
import PrivacyImage from 'images/NavDrawer/privacy.svg';
import ProjectsImage from 'images/NavDrawer/projects.svg';
import TermsOfUseImage from 'images/NavDrawer/termsofuse.svg';

import NavListItem from './NavListItem';

interface SideDrawerProps extends /*WithStyles<typeof styles>, */ DataAwareProps {
  hello?: string;
}

@withData
@observer
class SideDrawer extends React.Component<SideDrawerProps> {
  public render() {
    const handleClick = (event: any) => {
      event.stopPropagation();
      this.props.data.GlobalState.legalMenuExpanded = !this.props.data.GlobalState
        .legalMenuExpanded;
    };

    const toggleDrawer = (open: boolean) => (event: any) => {
      if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return;
      }
      //console.log("From SideDrawer");
      //console.log("this.props.data.GlobalState.drawerOpen before: " + this.props.data.GlobalState.drawerOpen);
      this.props.data.GlobalState.drawerOpen = open;
      //console.log("this.props.data.GlobalState.drawerOpen after: " + this.props.data.GlobalState.drawerOpen);
      event.stopPropagation();
    };

    return (
      <div>
        <React.Fragment key="left">
          <Grid container={true} spacing={0} alignItems="center" justify="center">
            <Grid item={true}>
              <KAUNToggle size={48} />
            </Grid>
            <Grid item={true}>
              <KAUN variant="h4" to="/" />
            </Grid>
          </Grid>

          <Drawer
            anchor="left"
            open={this.props.data.GlobalState.drawerOpen}
            onClose={toggleDrawer(false)}>
            <div role="presentation" onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
              <List>
                <ListItem button={true} key="Project Portal">
                  <KAUNToggle size={220} />
                </ListItem>
                <ListItem button={true} component={RouterLink} to="/">
                  <ListItemSecondaryAction>
                    <HyperLink to="/">
                      <KAUN />
                    </HyperLink>
                  </ListItemSecondaryAction>
                </ListItem>
              </List>

              <Divider />
              <List>
                <NavListItem to="/projects" title="Project Portal" icon={ProjectsImage} />
              </List>
              <Divider />
              <List>
                <NavListItem onClick={handleClick} title="Legal" icon={LegalImage}>
                  {this.props.data.GlobalState.legalMenuExpanded ? <ExpandLess /> : <ExpandMore />}
                </NavListItem>
                <Collapse
                  in={this.props.data.GlobalState.legalMenuExpanded}
                  timeout="auto"
                  unmountOnExit={true}>
                  <List component="div" disablePadding={true}>
                    <NavListItem
                      to="/legal/terms-of-use"
                      title="Terms of Use"
                      icon={TermsOfUseImage}
                      nested={true}
                    />
                    <NavListItem
                      to="/legal/privacy-policy"
                      title="Privacy Policy"
                      icon={PrivacyImage}
                      nested={true}
                    />
                  </List>
                </Collapse>
              </List>
              <Divider />

              <SocialBar />
              <Divider />
            </div>
          </Drawer>
        </React.Fragment>
      </div>
    );
  }
}

export default /*withStyles(styles)*/ SideDrawer;
