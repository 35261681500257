import { Theme } from '@material-ui/core';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';
import React from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { DataAwareProps, withData } from 'components/DataContext';
import Page from 'components/Page';
import { RouteComponentProps } from 'react-router';

import PressImage from 'images/NavDrawer/press.svg';
import ProjectsImage from 'images/NavDrawer/projects.svg';

import IconButton from '@material-ui/core/IconButton';
import HyperLink from 'components/Links/HyperLink';

import LOGO from 'images/t_hangar_photo.png';
import PHOTO from 'images/east_end_expansion.jpg';

import EmailLink from 'components/Links/EmailLink';


const styles = (theme: Theme) => createStyles({});

interface HomeProps extends WithStyles<typeof styles>, RouteComponentProps<{}>, DataAwareProps {
  match: any;
}

@withData
class Home extends React.Component<HomeProps> {
  public render() {
    //    const { classes } = this.props;

    return (
      <Page disableCrumbs={true} title="KAUN East Hangar Development | Greater Sacramento Area">
        <Grid container={true} spacing={0} alignItems="center" justify="center" direction="column">
          <Grid item={true}>
            <br /><br /><br /><br />
            <img src={LOGO} width="75% "alt="Logo" />
          </Grid>

          <Grid item={true}>
            <Grid container={true} spacing={3} alignItems="center" justify="center">

              <Grid item={true}>
                    <Grid
                      container={true}
                      spacing={0}
                      alignItems="center"
                      justify="center"
                      direction="column">
                      <Grid item={true}>

        <Typography variant="body1" color="textPrimary" paragraph={true}>
KAUN East Hangar Development LLC is pleased to announce the development of 37 new hangars (75,000 sq ft) on the east end of the Auburn Municipal Airport (KAUN). Hangar options are 60’x60’, 50’x50’ and 48’x42’ executive hangars, and 2 rows of 50’x231’ condo style hangar buildings partitioned into T-hangars 42'x33" each.
        </Typography>
        <Typography variant="body1" color="textPrimary" paragraph={true}>
Construction is scheduled to begin
in July 2022. 
        </Typography>
        <img src={PHOTO} width="75% "alt="Photo" />
        <Typography variant="body1" color="textPrimary" paragraph={true}>
For more information on pricing and
schedule, contact Steve Hogge at <EmailLink user={'inquiries'} />

        </Typography>



                      </Grid>
                    </Grid>
              </Grid>

            </Grid>
          </Grid>
        </Grid>
      </Page>
    );
  }
}

export default withStyles(styles)(Home);
