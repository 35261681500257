import { Theme } from '@material-ui/core';
import Typography, { TypographyProps } from '@material-ui/core/Typography';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';
import HyperLink from 'components/Links/HyperLink';
import React from 'react';

const styles = (theme: Theme) => createStyles({});

interface KAUNProps extends WithStyles<typeof styles> {
  to?: string;
  variant?: TypographyProps['variant'];
  TypographyClasses?: any;
}

class KAUN extends React.Component<KAUNProps> {
  public renderWrappedBody() {
    return <HyperLink to="/">{this.renderBody()}</HyperLink>;
  }
  public renderBody() {
    const variant = this.props.variant ? this.props.variant : 'inherit';
    return (
      <React.Fragment>
        <Typography
          style={{ fontWeight: 600 }}
          component="span"
          variant={variant}
          color="textSecondary"
          align="center"
          >
          KAUN
        </Typography>
        <Typography
          style={{ fontWeight: 450 }}
          component="span"
          variant={variant}
          color="textPrimary"
          align="center"
          >
          &nbsp;
        </Typography>
        <Typography
          style={{ fontWeight: 450 }}
          component="span"
          variant={variant}
          color="textPrimary"
          align="center"
          >
          East Hangar Development LLC
        </Typography>
      </React.Fragment>
    );
  }

  public render() {
    if (this.props.to) {
      return this.renderWrappedBody();
    } else {
      return this.renderBody();
    }
  }
}

export default withStyles(styles)(KAUN);
