import { Theme } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';
import HyperLink from 'components/Links/HyperLink';
import SocialBar from 'components/SocialBar';
import KAUNDevLogo from 'images/kaun-dev-truck.svg';
import React from 'react';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: 'auto',
      padding: `${theme.spacing(3)}px 0`,
      borderTop: `1px solid ${theme.palette.divider}`,
      [theme.breakpoints.up('md')]: {
        width: 800,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
      [theme.breakpoints.up('lg')]: {
        width: 1000,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },
    logo: {
      display: 'inline-block',
      height: 32,
      width: 'auto',
    },
    links: {},
    link: {
      color: 'inherit',
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'none',
      },
    },
  });

interface FooterProps extends WithStyles<typeof styles> {}

class Footer extends React.Component<FooterProps> {
  public render() {
    const { classes } = this.props;

    return (
      <Grid
        component="footer"
        container={true}
        spacing={1}
        direction="column"
        justify="center"
        alignItems="center"
        className={classes.root}>
        <SocialBar />
        <Grid container={true} justify="center" spacing={4}>
          <Grid item={true}>
            <HyperLink
              to="/legal/terms-of-use"
              text="Terms of Use"
              variant="body2"
              color="primary"
              button="true"
            />
          </Grid>
          <Grid item={true}>
            <HyperLink to="/">
              <img src={KAUNDevLogo} className={classes.logo} alt="" />
            </HyperLink>
          </Grid>
          <Grid item={true}>
            <HyperLink
              to="/legal/privacy-policy"
              text="Privacy Policy"
              variant="body2"
              color="primary"
              button="true"
            />
          </Grid>
        </Grid>
        <Grid item={true}>
          <Typography align="center" variant="body2" color="secondary">
            © 2022 KAUN East Hangar Development LLC
          </Typography>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(Footer);
