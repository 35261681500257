import { Theme } from '@material-ui/core';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';
import { AuthAwareProps, withAuth } from 'components/AuthContext';
import React from 'react';
import { Redirect, RouteComponentProps, withRouter } from 'react-router';

import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import KAUN from 'components/KAUN';
import Page from 'components/Page';
import ProjectsImage from 'images/NavDrawer/projects.svg';

const emailRegex =
  // eslint-disable-next-line
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: 'auto',
      display: 'block', // Fix IE 11 issue.
      margin: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
      [theme.breakpoints.up(400 + theme.spacing(3) * 2)]: {
        width: 400,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },
    paper: {
      margin: `${theme.spacing(10)}px 0px`,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
      color: theme.palette.text.secondary,
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    textField: {
      width: '100%',
      align: 'center',
      marginTop: theme.spacing(1),
    },
    errorText: {
      marginTop: theme.spacing(1),
    },
    submit: {
      marginTop: theme.spacing(3),
    },
  });

interface LoginProps extends WithStyles<typeof styles>, RouteComponentProps<{}>, AuthAwareProps {}

interface LoginState {
  loading: boolean;
  redirect: boolean;
  email: string;
  password: string;
  emailValid: 'success' | 'error' | 'warning' | undefined;
  emailShowError: boolean;
  passwordValid: 'success' | 'error' | 'warning' | undefined;
  passwordShowError: boolean;
  submitShowError: boolean;
}

class Login extends React.Component<LoginProps, LoginState> {
  constructor(props: LoginProps) {
    super(props);

    this.state = {
      loading: false,
      redirect: props.isAuthenticated,
      email: '',
      password: '',
      emailValid: undefined,
      emailShowError: false,
      passwordValid: undefined,
      passwordShowError: false,
      submitShowError: false,
    };
  }

  public onEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;
    this.setState({
      email: target.value,
      emailValid: emailRegex.test(target.value.toLowerCase()) ? 'success' : 'error',
      emailShowError: this.state.emailShowError
        ? !emailRegex.test(target.value.toLowerCase())
        : false,
      submitShowError: false,
    });
  };

  public onEmailBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
    //const target = event.target as HTMLInputElement;
    this.setState({
      emailShowError: this.state.emailValid !== 'success',
    });
  };

  public onPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;
    this.setState({
      password: target.value,
      passwordValid: target.value.length < 8 ? 'error' : 'success',
      passwordShowError: this.state.passwordShowError ? target.value.length < 8 : false,
      submitShowError: false,
    });
  };

  public onPasswordBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
    //const target = event.target as HTMLInputElement;
    this.setState({
      passwordShowError: this.state.passwordValid !== 'success',
    });
  };

  public onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    this.setState({ loading: true });

    try {
      await this.props.handleLogin(this.state.email, this.state.password);
      this.setState({ redirect: true });
    } catch (e) {
      console.error(e.message);
      this.setState({
        loading: false,
        submitShowError: true,
      });
    }
  };

  public render() {
    const { referrer } = this.props.location.state || { referrer: { pathname: '/' } };
    if (this.state.redirect) {
      return <Redirect to={referrer} />;
    }

    const { classes } = this.props;
    return (
      <Page disableCrumbs={true} title="KAUN East Hangar Development | Project Portal Login">
        <div className={classes.root}>
          <Paper className={classes.paper}>
            <img width={200} src={ProjectsImage} alt="" />

            <Typography component="h1" variant="h5" paragraph={true}>
              <KAUN />
            </Typography>
            <Typography variant="h5" color="primary" align="center" paragraph={true}>
              Project Portal Login
            </Typography>

            <form
              className={classes.form}
              onSubmit={this.onSubmit}
              noValidate={true}
              autoComplete="off">
              <TextField
                id="outlined-email-input"
                label="Email"
                className={classes.textField}
                type="email"
                name="email"
                autoComplete="email"
                margin="normal"
                variant="outlined"
                error={this.state.emailShowError}
                value={this.state.email}
                onBlur={this.onEmailBlur}
                onChange={this.onEmailChange}
              />
              <TextField
                id="outlined-password-input"
                label="Password"
                className={classes.textField}
                type="password"
                name="password"
                autoComplete="current-password"
                margin="normal"
                variant="outlined"
                error={this.state.passwordShowError}
                value={this.state.password}
                onBlur={this.onPasswordBlur}
                onChange={this.onPasswordChange}
              />
              {this.state.submitShowError && (
                <Typography
                  variant="subtitle2"
                  color="error"
                  align="center"
                  className={classes.errorText}>
                  Invalid username or password
                </Typography>
              )}
              <Button
                variant="outlined"
                className={classes.submit}
                fullWidth={true}
                type="submit"
                disabled={
                  this.state.passwordValid !== 'success' || this.state.emailValid !== 'success'
                }>
                Sign in
              </Button>
            </form>
          </Paper>
        </div>
      </Page>
    );
  }
}

export default withRouter(withAuth(withStyles(styles)(Login)));
