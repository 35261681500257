import { Theme } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';
import { DataAwareProps, withData } from 'components/DataContext';
import KAUNDevLogo from 'images/kaun-dev-truck.svg';
import { observer } from 'mobx-react';
import React from 'react';

const styles = (theme: Theme) =>
  createStyles({
    nothing: {},
  });

interface KAUNToggleProps extends WithStyles<typeof styles>, DataAwareProps {
  size?: number;
}

@withData
@observer
class KAUNToggle extends React.Component<KAUNToggleProps> {
  public render() {
    const toggleDrawer = () => (event: any) => {
      if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return;
      }
      //console.log("\n");
      //console.log("From KAUNToggle");
      //console.log("this.props.data.GlobalState.drawerOpen before: " + this.props.data.GlobalState.drawerOpen);
      this.props.data.GlobalState.drawerOpen = !this.props.data.GlobalState.drawerOpen;
      //console.log("this.props.data.GlobalState.drawerOpen after: " + this.props.data.GlobalState.drawerOpen);
      //console.log("\n");
      event.stopPropagation();
    };

    const sz = this.props.size ? this.props.size : 250;

    return (
      <IconButton onClick={toggleDrawer()}>
        <img width={sz} src={KAUNDevLogo} alt="Navigate KAUN.dev" />
      </IconButton>
    );
  }
}

export default withStyles(styles)(KAUNToggle);
