import { Theme } from '@material-ui/core';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { createStyles, withStyles } from '@material-ui/styles';
import HyperLink from 'components/Links/HyperLink';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import Path from './Path';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
  });

const root = new Path(true, '', 'Home');

const legal = root.createChild(false, 'legal');
legal.createChild(true, 'terms-of-use');
legal.createChild(true, 'privacy-policy');

const projects = root.createChild(true, 'projects');
/*const project = */ projects.createChild(true, '?');

/*/const research = */ root.createChild(true, 'research');
/*const press = */ root.createChild(true, 'press');

interface CrumbsBarProps extends RouteComponentProps<{}> {}

class CrumbsBar extends React.Component<CrumbsBarProps> {
  public toDisplayableString = (str: string) => {
    str = str.replace(/-/g, ' '); //hyphens to spaces
    str = str.replace(/([A-Z])/g, ' $1').trim(); //Insert space before new capital letter
    str = str.replace(
      //Capitalize first letters of every word
      /\w\S*/g,
      (txt: string) => {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
    );
    return str;
  };

  public getCrumb = (index: number) => {
    const { location } = this.props;
    const pathnames = location.pathname.split('/');
    const to = `${pathnames.slice(0, index + 1).join('/')}`;

    let p = root;
    for (let i = 1; i <= index; i++) {
      p = p.get(pathnames[i]);
    }
    if (p && p.visible) {
      const title = p.title ? p.title : this.toDisplayableString(pathnames[index]);
      return (
        // @ts-ignore
        <HyperLink to={to} key={to} text={title} variant="body1" color="secondary" button="true" />
      );
    }
    return null;
  };

  public render() {
    const { location } = this.props;
    const pathnames = location.pathname.split('/');
    return (
      <div>
        <Breadcrumbs>{pathnames.map((value, index) => this.getCrumb(index))}</Breadcrumbs>
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(CrumbsBar));
