import { Theme } from '@material-ui/core';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';
import { DataAwareProps, withData } from 'components/DataContext';
import HyperLink from 'components/Links/HyperLink';
import Page from 'components/Page';
import { observer } from 'mobx-react';
import numeral from 'numeral';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

numeral.defaultFormat('0,000');

const styles = (theme: Theme) => createStyles({});

interface ProjectsPageProps
  extends WithStyles<typeof styles>,
    RouteComponentProps<{}>,
    DataAwareProps {}

@withData
@observer
class ProjectsPage extends React.Component<ProjectsPageProps> {
  public render() {
    if (window.logRenders) {
      console.log('Rendering ProjectsPage');
    }
    const { data } = this.props;
    const projects = data.getProjectsArray();
    return (
      <Page title="KAUN East Hangar Development | Projects">
        {projects.map((project: any, index: number) => {
          return (
            <React.Fragment key={index}>
              <HyperLink
                to={project.getPath()}
                text={project.name}
                variant="body2"
                color="primary"
              />
            </React.Fragment>
          );
        })}
      </Page>
    );
  }
}

export default withRouter(withStyles(styles)(ProjectsPage));
